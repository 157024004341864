import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
export default function Landing(){
    const [state,setState] = React.useState(1);
    const [times,setTimes] = React.useState(false);
    
    return(
<Box sx={{ flexGrow: 1 }}>

                <Grid container className="footer ps-5">
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className="footer_content footer_logo">
            <Link to="/"><img src={Logo} className="img-fluid footer_logo" /></Link>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={9} xs={12} className="footer_content">
            <p>The ctoswallet.com website and chiptoss cryptocurrency wallet mobile app are owned by the WEME LTD SIA company incorporated in the Latvia, EU with company Registration No. 40203447245, registered office at Lienes iela 1 - 3, Riga LV 1009 Latvia, EU which is regulated by the laws of that country. WEME POLAND LIMITED LIABILITY COMPANY incorporated in Poland with NIP number 7252327218 with a registered office at ul. PIOTRKOWSKA, nr 116, lok. 52, miejsc. ŁÓDŹ, kod 90-006, poczta ŁÓDŹ, kraj POLSKA. chiptoss cryptocurrency wallet mobile app has been granted VASP license No. 7011117270 for providing a cryptocurrency service institution WEME LTD SIA and WEME POLAND LIMITED LIABILITY COMPANY is a limited liability company created and existing under the laws of LATVIA and Poland, EU. The chiptoss cryptocurrency wallet mobile app is serviced based on the VASP virtual asset license acquired by WEME POLAND LIMITED LIABILITY COMPANY. Crypto(Digital)currency values are not static and fluctuate due to market changes. Not all products and services are available in all geographic areas and are subject to applicable terms and conditions.</p>
            
            </Grid>
            <Grid container spacing={3}>   
          <Grid item xl={9} lg={9} md={9} sm={9} xs={12} className="footer_content">
        <Box className="py-5 requirements">
          <ul className="footer-content flex">
            <Link><li>Requirements</li></Link>
            <Link><li>Restricted Countries</li></Link>
            <Link><li>Documentation</li></Link>
          </ul>
        </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className="footer_content footer_visa">
        <Box className="footer-visa">
          <img src={require("../assets/images/visa.png")} className="img-fluid" />
        </Box>  
        </Grid>
        </Grid>
            </Grid>
           
        <div className="flex-between pe-5 w-100 social">
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Box className="py-3">
          <ul className="social-icons flex">
            <li><i class='fa fa-facebook'></i></li>
            <li><i class='fa fa-twitter'></i></li>
            <li><i class='fa fa-instagram'></i></li>
            <li><i class='fa fa-youtube'></i></li>
          </ul>
        </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="footer-store1">
        <Box>
          <ul className="footer-store flex">
            <li><img src={require("../assets/images/google-play.png")} className="img-fluid pe-2" />Google Play</li>
            <li><img src={require("../assets/images/apple.png")} className="img-fluid pe-2" />App Store</li>
            <li>Open a free wallet</li>
          </ul>
        </Box>
        </Grid>
        </Grid>
        </div>

    </Box>
    )
}