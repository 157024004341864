import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import Header from "../screens/Header";
import Footer from "../screens/Footer";
import User from "./User";

export default function Landing(){
    React.useEffect(() => {   
        const element = document.getElementById("home");
        element.scrollIntoView();
    });
    return(
        <>
<Box sx={{ flexGrow: 1 }} className="home" id="home">
    <Grid container spacing={2}>
        <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
        <Header/>
        </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} className="borderLeft">
            <User />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="multicryptoleft">
            <Box className="multicryptowallet pt-4">
            <h1>Multi Crypto Wallet</h1>
            <p className="description py-2">ctos wallet is a powerful all-in-one multi-coin wallet compatible with both 
mobile and desktop devices. Boasting unparalleled generous functionality, this awesome app lets you buy, trade, store, withdraw, spend, transfer and invest your digital holdings from a single dashboard.</p>
            </Box>    
            <Box className="flex">
                <Box className="ios_mobile">
                    <img src={require("../assets/images/ios.png")} className="img-fluid" />
                </Box>
                <Box className="android_mobile">
                    <img src={require("../assets/images/android.png")} className="img-fluid" />
                </Box>
                <Box>
                    <button className="linear-gradient">Open a free wallet</button>
                </Box>
            </Box>
            {/* <Box className="banner">
                <img src={require("../assets/images/banner.png")} className="img-fluid" />
            </Box> */}
            <Box className="ps-5 main_content">
            <Box className="thirty_supported py-2">
                <h2>30+ coins supported</h2>
                <p className="py-2">Ctoswallet.com supports 30+ most popularcryptocurrencies, including top-performing CTOS, Bitcoin, Ethereum, Dash, Ripple as well as emerging coins like Augur, Tether,  Dai to name a few out of too many</p>
             <Box className="pt-4">
                <Grid container spacing={2}>
                   <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin1.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin2.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin3.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin4.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin5.png")} className="img-fluid" />
                    </Grid>    
                   <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin6.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin7.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin8.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin9.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin10.png")} className="img-fluid" />
                    </Grid>    
                </Grid>
             </Box>           
            </Box>
            <Box className="get_started py-5">
                <h2>Get Started in three simple steps</h2>
                <p className="pb-2">It only takes a few minutes</p>
            </Box>
            <Box className="three_steps py-5">
                <Grid container spacing={5}>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <div className="three_simple_steps">
                        <img src={require("../assets/images/download.png")} className="img-fluid" />
                         <p className="s1">Step 1</p>
                         <p className="description">Download Chiptoss Wallet</p>
                     </div>
                    </Grid> 
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className="three_simple_steps">
                    <img src={require("../assets/images/createnew.png")} className="img-fluid" />
                         <p className="s1">Step 2</p>
                         <p className="description">Create a new wallet</p>
                    </div>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <div className="three_simple_steps">
                     <img src={require("../assets/images/crypto.png")} className="img-fluid" />
                     <p className="s1">Step 3</p>
                         <p className="description">Get some crypto</p>
                    </div>
                    </Grid>
                </Grid>
            </Box>
            <Box>
            <Grid container className="clients py-5">
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="seventy">
                <h2>
                    75,000 Clients entrust their digital assets to us
                </h2>
                <p>
                We are a licensed, fully regulated financial institution in the European Union. Your account is protected by a 3-layer  security, an anti-fraud protocol and insured by BitGo.
                </p>
              </Grid>   
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="clients_img">
                <img src={require("../assets/images/secure.png")} className="img-fluid" />
                 
              </Grid> 
            </Grid>
            </Box>
            </Box>
            <Box>
                <Grid container  spacing={2} className="brand_bg">
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand1.png")} className="img-fluid"/>
                    </Grid>
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand2.png")} className="img-fluid"/>
                    </Grid>
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand3.png")} className="img-fluid"/>
                    </Grid>
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand4.png")} className="img-fluid"/>
                    </Grid>
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand5.png")} className="img-fluid"/>
                    </Grid>
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand6.png")} className="img-fluid"/>
                    </Grid>
                    <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12} className="brand">
                    <img src={require("../assets/images/brand7.png")} className="img-fluid"/>
                    </Grid>
                </Grid>
            </Box>
            <Box className="main_content">
            <Box className="pt-5">
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                            <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund1.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                       <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund2.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                       <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund3.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                       <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund4.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                </Grid>
            </Box>
            </Box>
        </Grid>
        <Footer/>
      </Grid>
      </Grid>
      
    </Box>
   
    </>
    )
}