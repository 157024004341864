import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import Header from "../screens/Header";
import Footer from "../screens/Footer";
import User from "./User";
export default function ReferralProgram(){
  React.useEffect(() => {   
    const element = document.getElementById("referralprogram");
    element.scrollIntoView();
});
    return(
      <>
<Grid container spacing={2} className="program">
        <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
        <Header />
        </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} className="borderLeft">
            <User />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="multicryptoleft">
        <Box className="multicryptowallet pt-4">
            <h1>Multi Crypto Wallet</h1>
            <p className="description py-2">ctos wallet is a powerful all-in-one multi-coin wallet compatible with both 
mobile and desktop devices. Boasting unparalleled generous functionality, this awesome app lets you buy, trade, store, withdraw, spend, transfer and invest your digital holdings from a single dashboard.</p>
            </Box>  
            <Box className="flex">
                <Box className="ios_mobile">
                    <img src={require("../assets/images/ios.png")} className="img-fluid" />
                </Box>
                <Box className="android_mobile">
                    <img src={require("../assets/images/android.png")} className="img-fluid" />
                </Box>
                <Box>
                    <button className="linear-gradient">Open a free wallet</button>
                </Box>
            </Box>
            {/* <Box className="banner">
                <img src={require("../assets/images/banner.png")} className="img-fluid" />
            </Box>    */}
            <Box className="main_content pt-5" id="referralprogram">
            <Grid container className="pb-3">
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="py-4 invite_friends">
                <h1>
                Invite Friends & Earn with us
                </h1>
                <p className="py-3">
                We are a licensed, fully regulated financial institution in the European Union. Your account is protected by a 3-layer  security, an anti-fraud protocol and insured by BitGo.
                </p>
                <button className="linear-gradient">Invite friends</button> 
              </Grid>   
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src={require("../assets/images/invitefriends.png")} className="img-fluid" />
                 
              </Grid> 
            </Grid>
            
            <Box className="violet-border mt-5 steps">
              <Grid container spacing={5}>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className="marginTop">
                    <img src={require("../assets/images/s1.png")} className="img-fluid" />
                    <h2>Send invites</h2>
                    <p>Send a $5 gift at our expense to your friends from a contact list or share your link around the web</p>
                    <Box className="avatara">
                    <img src={require("../assets/images/mask1.png")} className="img-fluid" />    
                    <img src={require("../assets/images/avatar.png")} className="img-fluid" />
                    <img src={require("../assets/images/mask2.png")} className="img-fluid" />
                    </Box>
                    {/* <img src={require("../assets/images/mask2.png")} className="img-fluid" /> */}
                    <button className="pink-btn">More</button>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className="marginTop">
                <img src={require("../assets/images/s2.png")} className="img-fluid" />
                <h2>Send a $5 gift</h2>
                    <p>send invites send a $5 gift at our expense to your friends from a contact list or share your link around the webreceive a $5 gift. When your invited friend registers an account and makes one of crypto-to-fiat 
transactions from the list below for at least €50 you both receive your $5 bonuses in CTOS!
</p>
                    <img src={require("../assets/images/s2IMG.png")} className="img-fluid" />    
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className="marginTop">
                <img src={require("../assets/images/s3.png")} className="img-fluid" />
                <h2>earn 25% on their comissions</h2>
                    <p>send invites send a $5 gift at our expense to your friends from a contact list or share your link around the webreceive a $5 gift. When your invited friend registers an account and makes one of crypto-to-fiat 
transactions from the list below for at least €50 you both receive your $5 bonuses in CTOS!
</p>
                    
                </Grid>
              </Grid>
            </Box>
            <Box className="more mt-5">
                <img src={require("../assets/images/More.png")} className="img-fluid" />
                <div className="content">
                    <h2>more than</h2>
                    <h2>1,400,000 CTOS</h2>
<p>We have already given away more than 400k CRPT to our users for bringing new people toctoswallet.com. Keep your friends active and earn more!</p>
                </div>
            </Box>
            <Box className="py-5 faq">
                <h2 className="pb-4">FAQ</h2>
                <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      When will I receive my reward?
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>You will receive rewards after you and
your invited friends successfully verify
your identity and make fiat-to-fiat
transactions (purchases, cash withdrawals,
cards) worth $100 or more.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      How many people can I invite?
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>There is no limit on invited friends.</p>
      </div>
    </div>
  </div>
</div>
            </Box>
            </Box>
        </Grid>
      
        <Footer /></Grid>
   </Grid>
    
    
    </>
    )
}