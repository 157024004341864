import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
export default function User(){
    const [state,setState] = React.useState(1);
    const [times,setTimes] = React.useState(false);
    return(
    <Box className="user pt-4">
            <div className="normal-user">
            <ul className="flex-end">
              <li><img src={require("../assets/images/bell.png")} className="img-fluid" /></li>
              <li><img src={require("../assets/images/user.png")} className="img-fluid" /></li>
              <li><img src={require("../assets/images/avatar.png")} className="img-fluid" /></li>
            </ul>
            </div>
            <Box className="mobile-navbar flex">
              <div className="flex-start">
              <i class="fa fa-bars" aria-hidden="true"  onClick={() => setTimes(true)}></i>
              <img src={Logo} className="img-fluid logo" />
              </div>
              <ul className="flex ">
              <li><img src={require("../assets/images/bell.png")} className="img-fluid" /></li>
              <li><img src={require("../assets/images/user.png")} className="img-fluid" /></li>
              <li><img src={require("../assets/images/avatar.png")} className="img-fluid" /></li>
            </ul>
            </Box>
            <Box>
            
            </Box>
            <Box className={times == true ? "mobile-menu d-block" : "mobile-menu d-none"}>
          <Box className="button_side">
          <i class="fa fa-times" aria-hidden="true" onClick={() => setTimes(false)}></i>
          <ul>
             <li><NavLink to="/">CryptoWallet</NavLink></li>
             <li><NavLink to="/buy">Buy</NavLink></li>
             <li><NavLink to="/exchange">Exchange</NavLink></li>
             <li><NavLink to="/sendmoney">SendMoney</NavLink></li>
             <li><NavLink to="/card">Card</NavLink></li>
             <li><NavLink to="/referralprogram">ReferralProgram</NavLink></li>
             <li><NavLink to="/support">Support</NavLink></li>
             </ul>
          </Box>
          </Box>
          </Box>
    )
    
}