import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import Header from "../screens/Header";
import Footer from "../screens/Footer";
import User from "./User";
export default function SendMoney(){
    React.useEffect(() => {   
        const element = document.getElementById("sendmoney");
        element.scrollIntoView();
    });
    return(
        <>
        <Grid container spacing={2} className="send-money">
        <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
        <Header />
        </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} className="borderLeft">
            <User />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="multicryptoleft">
            <Box className="multicryptowallet pt-4">
            <h1>Multi Crypto Wallet</h1>
            <p className="description py-2">ctos wallet is a powerful all-in-one multi-coin wallet compatible with both 
mobile and desktop devices. Boasting unparalleled generous functionality, this awesome app lets you buy, trade, store, withdraw, spend, transfer and invest your digital holdings from a single dashboard.</p>
            </Box>    
            <Box className="flex">
                <Box className="ios_mobile">
                    <img src={require("../assets/images/ios.png")} className="img-fluid" />
                </Box>
                <Box className="android_mobile">
                    <img src={require("../assets/images/android.png")} className="img-fluid" />
                </Box>
                <Box>
                    <button className="linear-gradient">Open a free wallet</button>
                </Box>
            </Box>
            {/* <Box className="banner">
                <img src={require("../assets/images/banner.png")} className="img-fluid" />
            </Box> */}
            <Box className="main_content" id="sendmoney">
            <Box>
                <Grid container className="easiest_fastest">
                    
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h2 className="pt-5">Send money anywhere
                        for free</h2>
                            <p className="py-4">Buy cryptocurrency online with fiat money through chiptoss. Learn
                            about wallet fees for fiat-to-crypto purchases, coins available, and how
                            ctoswallet.com offers attractive rates on cryptocurrency trading.
                            </p>
                            <button className="linear-gradient">Send Money</button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="easiest_fastest_way">
                        <img src={require("../assets/images/Sendmoney.png")} className="img-fluid" />
                    </Grid>
                </Grid>
            </Box>
            <Box className="">
            <Box className="thirty_supported py-5">
                <h2>30+ coins supported</h2>
                <p className="py-5">Ctoswallet.com supports 30+ most popularcryptocurrencies, including top-performing CTOS, Bitcoin, Ethereum, Dash, Ripple as well as emerging coins like Augur, Tether,  Dai to name a few out of too many</p>
             <Box>
                <Grid container spacing={2}>
                   <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin1.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin2.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin3.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin4.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin5.png")} className="img-fluid" />
                    </Grid>    
                   <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin6.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin7.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin8.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin9.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={2.4} lg={2.4} md={2.4} sm={3} xs={12} className="coin">
                    <img src={require("../assets/images/coin10.png")} className="img-fluid" />
                    </Grid>    
                </Grid>
             </Box>           
            </Box>
            <Box>
                <Grid container className="easiest_fastest">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="easiest_fastest_way">
                        {/* <ul className="flex">
                            <li><img src={require("../assets/images/nofees.png")} className="img-fluid" /></li>
                            <li><img src={require("../assets/images/instant.png")} className="img-fluid" /></li>
                            <li><img src={require("../assets/images/worldwide.png")} className="img-fluid" /></li>
                        </ul> */}
                        <img src={require("../assets/images/Map.png")} className="img-fluid" />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container className="easiest_fastest">
                    
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h2 className="pt-5">How it works</h2>
                            <p className="py-4">Buy cryptocurrency online with fiat money through chiptoss. Learn
                            about wallet fees for fiat-to-crypto purchases, coins available, and how
                            ctoswallet.com offers attractive rates on cryptocurrency trading.
                            </p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="easiest_fastest_way">
                        <img src={require("../assets/images/howit.png")} className="img-fluid" />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container  className="easiest_fastest pt-5">
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="easiest_fastest_way">
                        <img src={require("../assets/images/Sendcrypto.png")} className="img-fluid" />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h2 className="pt-5">Send crypto as a gift</h2>
                            <p className="py-4">Buy cryptocurrency online with fiat money through chiptoss. Learn
                            about wallet fees for fiat-to-crypto purchases, coins available, and how
                            ctoswallet.com offers attractive rates on cryptocurrency trading.
                            </p>
                            <p className="pb-1">Buy cryptocurrency online with fiat money through chiptoss. Learn
                            about wallet fees for fiat-to-crypto purchases, coins available, and how
                            ctoswallet.com offers attractive rates on cryptocurrency trading.
                            </p>
                    </Grid>
                </Grid>
            </Box>
            </Box>
            </Box>
        </Grid>
        <Footer />
      </Grid>
    </Grid>
    </>
    )
}