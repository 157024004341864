import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
export default function Landing(){
    const [state,setState] = React.useState(1);
    const [times,setTimes] = React.useState(false);
    return(
<Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className="fullbody">
        <Grid item xl={2.5} lg={2.5} md={2.5} sm={12} xs={12} className="navabar">
            <Box className="menu_navbar">
          <Box className="normal-menu">
          <Box>
              <NavLink to="/"><img src={Logo} className="img-fluid" /></NavLink>
          </Box>
          <Box className="button_side">
            <ul>
             <li><NavLink to="/">Crypto Wallet</NavLink></li>
             <li><NavLink to="/buy">Buy</NavLink></li>
             <li><NavLink to="/exchange">Exchange</NavLink></li>
             <li><NavLink to="/sendmoney">Send Money</NavLink></li>
             <li><NavLink to="/card">Card</NavLink></li>
             <li><NavLink to="/referralprogram">Referral Program</NavLink></li>
             <li><NavLink to="/support">Support</NavLink></li>
             </ul>
          </Box>
          </Box>
          </Box>
          
          
        </Grid>
        {/* <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} className="main_content">
          <Box className="user pt-4">
            <Box className="mobile-navbar flex">
              <i class="fa fa-bars" aria-hidden="true"  onClick={() => setTimes(true)}></i>
              <img src={Logo} className="img-fluid" />
            </Box>
            <Box>
            <ul className="flex-end">
              <li><img src={require("../assets/images/bell.png")} className="img-fluid" /></li>
              <li><img src={require("../assets/images/user.png")} className="img-fluid" /></li>
              <li><img src={require("../assets/images/avatar.png")} className="img-fluid" /></li>
            </ul>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
    )
}