import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import Header from "../screens/Header";
import Footer from "../screens/Footer";
import User from "./User";
export default function Card(){
  React.useEffect(() => {   
    const element = document.getElementById("card");
    element.scrollIntoView();
});
    return(
      <>
<Grid container spacing={2}>
        <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
        <Header />
        </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} className="borderLeft">
            <User />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="multicryptoleft">
        <Box className="multicryptowallet pt-4">
            <h1>Multi Crypto Wallet</h1>
            <p className="description py-2">ctos wallet is a powerful all-in-one multi-coin wallet compatible with both 
mobile and desktop devices. Boasting unparalleled generous functionality, this awesome app lets you buy, trade, store, withdraw, spend, transfer and invest your digital holdings from a single dashboard.</p>
            </Box> 
            <Box className="flex">
                <Box className="ios_mobile">
                    <img src={require("../assets/images/ios.png")} className="img-fluid" />
                </Box>
                <Box className="android_mobile">
                    <img src={require("../assets/images/android.png")} className="img-fluid" />
                </Box>
                <Box>
                    <button className="linear-gradient">Open a free wallet</button>
                </Box>
            </Box>
            {/* <Box className="banner">
                <img src={require("../assets/images/banner.png")} className="img-fluid" />
            </Box>  */}
            <Box className="main_content mt-5 pt-5" id="card">
            <Grid container className="chiptoss_card">
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <h2>
                    Chiptoss Card
                </h2>
                <p>
                We are a licensed, fully regulated financial institution in the European Union. Your account is protected by a 3-layer  security, an anti-fraud protocol and insured by BitGo.
                </p>
                <div className="flex-start" style={{display:"flex",alignItems:"center",justifyContent:"flex-start",
              marginTop:25}}>
                <Box className="ios_mobile">
                    <img src={require("../assets/images/ios.png")} className="img-fluid" />
                </Box>
                <Box className="android_mobile">
                    <img src={require("../assets/images/playstore.jpg")} className="img-fluid" />
                </Box>
                <Box>
                    <button className="linear-gradient">Order Now</button>
                </Box>
            </div>
              </Grid>   
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="chiptoss-card">
                <img src={require("../assets/images/chiptoss-card.png")} className="img-fluid" />
              </Grid> 
            </Grid>
            <Box className="main_content">
            <Box className="pt-5">
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                            <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund1.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                       <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund2.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                       <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund3.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                       <div className="funds">
                       <div className="description">
                                Your funds are insured by bitgo for upto 0ne million
                            </div>
                            <div className="image">
                                <img src={require("../assets/images/fund4.png")} className="img-fluid" />
                            </div>
                        </div> 
                    </Grid>
                </Grid>
            </Box>
            </Box>
            <Box>
                <Grid container spacing={2} className="euro" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <Grid item xs={12} lg={6} md={6} sm={12} xl={6}>
                        <div className="european">
                          <img src={require("../assets/images/30.png")} className="img-fluid" />
                          <div className="content">
                                <h1>30</h1>
                                <p>European Countries available for order</p>
                                <button className="linear-gradient">Check available countries</button>
                          </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} sm={12} xl={6}>
                        <div className="cards_issued">
                            <div className="flex-between card_issued">
                            <h2>5,00,000+<p>Cards issued and continuing</p></h2>
                            <img src={require("../assets/images/card.png")} className="img-fluid wallet" />
                            </div>  
                            <div className="flex-between transaction">
                            <h2>5,00,000+<p>Transactions processed in monthly</p></h2>
                            <img src={require("../assets/images/Group2.png")} className="img-fluid coin" />
                          </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box className="pt-5">
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="chiptoss_plastic_card">
                <h2>
                Chiptoss plastic card
                </h2>
                <img src={require("../assets/images/CARDS.png")} className="img-fluid" />
                </Grid>   
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <div className="swipe">
                    <img src={require("../assets/images/Group-2.png")} className="img-fluid" />
                    <p className="pt-2 pb-3">Swipe, tap, or chip — pay with chiptoss Card in over 42 million physical and online retailers worldwide. Spend up to €10 000/mo</p>
               </div>
               <div className="swipe">
                    <img src={require("../assets/images/group-3.png")} className="img-fluid" />
                    <p className="pt-2 pb-3">Turning your digital assets into cash just got easier and faster than ever before. Withdraw up to €2 500/mo from Visa-compatible ATMs</p>
               </div> 
               <div className="swipe">
                    <img src={require("../assets/images/group3.png")} className="img-fluid" />
                    <p className="pt-2 pb-3">chiptoss Card is free, with express delivery starting at €14.99. Could it be any better? Yes, load it with €299 every month and pay ZERO monthly maintenance (€2.99/mo)
                  </p>
               </div>     
              </Grid> 
            </Grid>
            </Box>
            <Box className="pt-5">
            <Box className="green_colored">
                <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <h2 className="chiptoss_virtual_card py-3">
                        Chiptoss
                        virtual card
                        </h2>
                        <p>Your virtual chiptoss Card is all about saving you time. Submit your application and get your details in under 20 minutes. Less waiting, more fun!</p>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                       <img src={require("../assets/images/MaskGroup15.png")} className="img-fluid" />     
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="get_it py-3">
                    <h2>
                    get it instantly 
                    and for free
                        </h2>
                        <p>Your virtual chiptoss Card is all about saving you time. Submit your application and get your details in under 20 minutes. Less waiting, more fun!</p>
                    </Grid>
                </Grid>
            </Box>
            </Box>
            <Box>
            <Grid container className="clients py-5 mt-5">
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <h2>
                    75,000 Clients entrust their digital assets to us
                </h2>
                <p>
                We are a licensed, fully regulated financial institution in the European Union. Your account is protected by a 3-layer  security, an anti-fraud protocol and insured by BitGo.
                </p>
              </Grid>   
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src={require("../assets/images/secure.png")} className="img-fluid" />
                 
              </Grid> 
            </Grid>
            </Box>
            <Box className="easy_steps">
                    <h2>
                    Easy Steps to get your card
                    </h2>
                {/* <img src={require("../assets/images/Group76.png")} className="img-fluid" /> */}
                <Grid container spacing={4} className="violet-border ">
                  <Grid item xl={2.4} lg={2.4} md={6} sm={6} xs={12} className="marginTop">
                  <img src={require("../assets/images/s1.png")} className="img-fluid" />
                    <p>Create Your Wallet</p>
                  </Grid>
                  <Grid item xl={2.4} lg={2.4} md={6} sm={6} xs={12} className="marginTop">
                  <img src={require("../assets/images/s2.png")} className="img-fluid" />
                    <p>Select your preferred card type</p>
                  </Grid>
                  <Grid item xl={2.4} lg={2.4} md={6} sm={6} xs={12} className="marginTop">
                  <img src={require("../assets/images/s3.png")} className="img-fluid" />
                    <p>Fill in your details and pay for delivery</p>
                  </Grid>
                  <Grid item xl={2.4} lg={2.4} md={6} sm={6} xs={12} className="marginTop">
                  <img src={require("../assets/images/s4.png")} className="img-fluid" />
                    <p>Verify your identity and get approve in minutes</p>
                  </Grid>
                  <Grid item xl={2.4} lg={2.4} md={6} sm={6} xs={12} className="marginTop">
                  <img src={require("../assets/images/s5.png")} className="img-fluid" />
                    <p>Activate</p>
                  </Grid>
                </Grid>
            </Box>
            </Box>
        </Grid>
        <Footer /> 
      </Grid>
      </Grid>
      </>
    )
}