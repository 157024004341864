import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import './App.css';
import CryptoWallet from "../src/app/screens/CryptoWallet"
import Buy from "../src/app/screens/Buy"
import Exchange from "../src/app/screens/Exchange"
import SendMoney from "../src/app/screens/SendMoney"
import Card from "../src/app/screens/Card"
import ReferralProgram from "../src/app/screens/ReferralProgram"
import Support from "../src/app/screens/Support";
function App() {
  return (
    <BrowserRouter basename="/">
    <Routes>
      <Route path='/' element={<CryptoWallet/>} />
      <Route path='/buy' element={<Buy/>} />
      <Route path='/exchange' element={<Exchange/>} />
      <Route path='/sendmoney' element={<SendMoney/>} />
      <Route path='/card' element={<Card />} />
      <Route path='/referralprogram' element={<ReferralProgram/>} />
      <Route path='/support' element={<Support/>} />
  </Routes>
  </BrowserRouter>
  );
}

export default App;
