import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from "../assets/images/logo.png";
import Header from "../screens/Header";
import Footer from "../screens/Footer";
import User from "./User";
import { Link } from "react-router-dom";
export default function Support(){
    React.useEffect(() => {   
        const element = document.getElementById("support");
        element.scrollIntoView();
    });
    return(
        <>
       <Grid container spacing={2}>
        <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
        <Header />
        </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} className="borderLeft">
            <User />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="multicryptoleft">
        <Box className="multicryptowallet pt-4">
            <h1>Multi Crypto Wallet</h1>
            <p className="description py-2">ctos wallet is a powerful all-in-one multi-coin wallet compatible with both 
mobile and desktop devices. Boasting unparalleled generous functionality, this awesome app lets you buy, trade, store, withdraw, spend, transfer and invest your digital holdings from a single dashboard.</p>
            </Box>
            <Box className="flex">
                <Box className="ios_mobile">
                    <img src={require("../assets/images/ios.png")} className="img-fluid" />
                </Box>
                <Box className="android_mobile">
                    <img src={require("../assets/images/android.png")} className="img-fluid" />
                </Box>
                <Box>
                    <button className="linear-gradient">Open a free wallet</button>
                </Box>
            </Box>
            {/* <Box className="banner">
                <img src={require("../assets/images/banner.png")} className="img-fluid" />
            </Box> */}
            <Box className="main_content" id="support">  
            <Box className="search py-5">
                <h2>All that can help</h2>
                <div className="p-relative">
                <input type="text" placeholder="Ask a question" className="form-control" />
                <button className="linear-gradient"><img src={require("../assets/images/search.png")} className="img-fluid" />Search</button>
                </div>
            </Box>
            <Box className="pb-5 security">
                <h2><img src={require("../assets/images/lock.png")}  className="img-fluid" /> Security</h2>
            </Box>
            <Box className="documents_support pb-5">
                <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="flex-between border">
                <p>What documents can confirm the origin of my funds
on deposit?  </p> 
<img src={require("../assets/images/chevron-right.png")} className="img-fluid" />
</div>
</Grid>
<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
<div className="flex-between border">
                <p>What informations are about me can request for you? </p> 
<img src={require("../assets/images/chevron-right.png")} className="img-fluid" />
</div>
</Grid>
</Grid>
            </Box>
            <Box className="documents_support pb-5">
                <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="flex-between border">
                <p>What documents can confirm the origin of my funds
on deposit?  </p> 
<img src={require("../assets/images/chevron-right.png")} className="img-fluid" />
</div>
</Grid>
<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
<div className="flex-between border">
                <p>What documents can confirm the origin of my funds
on deposit?  </p> 
<img src={require("../assets/images/chevron-right.png")} className="img-fluid" />
</div>

</Grid>
</Grid>
            </Box>
            <Box>
            <Box className="need_how_to_guides">
                <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="need_to_know_more">
                    <img src={require("../assets/images/Group188.png")} className="img-fluid" />
                    <div className="content">
                <h2>Need to No more?</h2>
                 <p>Feel free to contact us 24/7 via</p>
                 <p><Link>support@ctoswallet.com </Link>or fill the request form</p>   
                 <button className="pin">Send Request</button>
                 </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="how_to_guides">
                    <img src={require("../assets/images/Rectangle103.png")} className="img-fluid" />
                <div className="content">
                <h2>How to guides?</h2>
                 <p>99% questions are already covered in our detailed guides</p> 
                 <button className="pin">Learn More</button> 
                 </div>         
                </Grid>
                </Grid>
                </Box>
            </Box>
            </Box>
        </Grid>
        <Footer />
      </Grid>
      </Grid>
    
    </>
    )
}